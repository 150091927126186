import {Component, HostListener, OnInit} from '@angular/core';
import {faDocker, faGithub, faJava, faPython, faReact, faWix, faAndroid} from '@fortawesome/free-brands-svg-icons';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.component.html',
  styleUrls: ['./card-project.component.css']
})
export class CardProjectComponent implements OnInit {

  python = faPython;
  react = faReact;
  docker = faDocker;
  java = faJava;
  wix = faWix;
  github = faGithub;
  externalLink = faExternalLinkAlt;
  android = faAndroid;
  kotlin = 'src/assets/kotlin.png';

  windowSize: boolean;

  project = [
    {
      title: 'Pulse',
      description: 'School project for a start-up in the USA.' +
        'Together with other developers I built an android application for children to check-in with their teacher. ' +
        'And try to resolve blockers with resources like videos. ' +
        'Authentication is based on firebase including Google SSO',
      technostack: [this.android],
      link: 'https://www.districtzero.co/',
      icon: this.externalLink,
      image: '/assets/pulse.png',
      imageAlt: 'Pulse',
      width: 150,
      height : 300

    },
    {
      title: 'Real time messenger',
      description: 'The realtime messenger is an application that is a relative simple messenger app which can be used to message others. ' +
        'This app is built with kotlin and build for android phones it uses dependencies as groupie and picasso. ' +
        'It also uses firebase for the authentication and saving the messages between users. ' +
        'It was a project for school which I made on my own.',
      technostack: [this.android],
      link: 'https://github.com/Sebaszjuh/CapstoneProjectMessenger',
      icon: this.github,
      image: '/assets/messengerChat.png',
      imageAlt: 'Real time messenger',
      width: 150,
      height : 300
    },

    {
      title: 'Crawlinator',
      description: 'The Crawlinator is an application that scrapes the dark web through a TOR-proxy.' +
        // tslint:disable-next-line:max-line-length
        'It uses scrapy and elasticsearch to scrape the required data and stores it. ' +
        'Users can search by keyword for the scraped data through a front-end web application built on React. ' +
        'This was a class project for Project CyberSecurity class where I worked with other students in a team of 6. ' +
        // tslint:disable-next-line:max-line-length
        'I was responsible for the front-end, and parts of the back-end. Examples are the layout of the scraped data, think of the fields. ' +
        'Besides that I implemented the proxy that connected to the TOR-network',
      technostack: [this.python, this.react, this.docker],
      link: 'https://github.com/Sebaszjuh/Crawlinator',
      icon: this.github,
      image: '/assets/crawlinator.png',
      imageAlt: 'crawlinaTOR',
      width: 550,
      height : 300
    },
    {
      title: 'Looking Fabulash',
      // tslint:disable-next-line:max-line-length
      description: 'Complete website for my sister her company. A salon booking website, including appointment booking system.',
      technostack: [this.wix],
      link: 'https://lookingfabulash.nl',
      icon: this.externalLink,
      image: '/assets/fabulash.png',
      imageAlt: 'Fabulash',
      width: 550,
      height : 300
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth >= 772) {
      this.windowSize = true;
    } else {
      this.windowSize = false;
    }
  }

  constructor() {
  }

  ngOnInit() {
    if (window.innerWidth >= 772) {
      this.windowSize = true;
    } else {
      this.windowSize = false;
    }
  }

}

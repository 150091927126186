import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LandingpageComponent} from './landingpage/landingpage.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AboutComponent} from './about-me/about.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing-module';
import {ProjectsComponent} from './projects/projects.component';
import {CardProjectComponent} from './projects/card-project/card-project.component';
import {LocationStrategy, Location, HashLocationStrategy} from '@angular/common';
import { OtherProjectComponent } from './projects/other-project/other-project.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    NavbarComponent,
    AboutComponent,
    ProjectsComponent,
    CardProjectComponent,
    OtherProjectComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    RouterModule
  ],
  providers: [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

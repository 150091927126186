import {Component, Input, OnInit} from '@angular/core';
import {faGithub, faLinkedin} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() githubLink: string;

  navLinks = [
    {
      pageName: 'Home',
      pagelink: '/',
    },
    {
      pageName: 'Projects',
      pagelink: '/projects'
    },
    {
      pageName: 'About me',
      pagelink: '/about'
    }
  ];

  faLinkedIn = faLinkedin;
  faGithub = faGithub;


  ngOnInit(): void {
  }


}

import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'websiteSebas';
  gitHubLink = 'https://github.com/Sebaszjuh';


  // getHubLink() {
  // return this.githubLink;
  // }
}


import {NavbarComponent} from '../app/navbar/navbar.component';
import {LandingpageComponent} from '../app/landingpage/landingpage.component';
import {AboutComponent} from './about-me/about.component';
import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {ProjectsComponent} from './projects/projects.component';


const routes: Routes = [
  {path: '', component: LandingpageComponent},
  {path: 'about', component: AboutComponent},
  {path: 'projects', component: ProjectsComponent},
  { path: '**', redirectTo: '/'}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

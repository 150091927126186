import {Component, HostListener, OnInit} from '@angular/core';
import {faGithub} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  constructor() { }

  linkedInLink = 'https://www.linkedin.com/in/sebastiaan-van-de-griendt-38a094110/';
  gitHubLink = 'https://github.com/Sebaszjuh';
  mailText:string = '';

  faGithub = faGithub;
  faLinkedIn = faLinkedin;
  faMailChimp = faEnvelope;

  innerWidth: any;
  ngOnInit() {
    if (window.innerWidth <= 426 && window.innerWidth > 376) {
      this.innerWidth = '4x';
    } else if (window.innerWidth <= 376) {
      this.innerWidth = '2x';
    } else {
      this.innerWidth = '6x';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 426 && window.innerWidth > 376) {
      this.innerWidth = '4x';
    } else if (window.innerWidth <= 376) {
      this.innerWidth = '2x';
    } else {
      this.innerWidth = '6x';
    }

  }

  mailMe() {
    this.mailText = 'mailto:sebastiaan@sgriendt.com?subject=Website&body=Dear Sebastiaan,'; // add the links to body
    window.location.href = this.mailText;
  }

}

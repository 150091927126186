import {Component, HostListener, OnInit} from '@angular/core';
import {faDocker, faGithub, faJava, faPython, faReact, faWix, faAndroid} from '@fortawesome/free-brands-svg-icons';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-other-project',
  templateUrl: './other-project.component.html',
  styleUrls: ['./other-project.component.css']
})
export class OtherProjectComponent implements OnInit {

  python = faPython;
  react = faReact;
  docker = faDocker;
  java = faJava;
  wix = faWix;
  github = faGithub;
  externalLink = faExternalLinkAlt;
  android = faAndroid;
  kotlin = 'src/assets/kotlin.png';

  windowSize: boolean;

  smallerProject = [
    {
      title: 'Rock paper scissor',
      // tslint:disable-next-line:max-line-length
      description: 'Small assignment I made for a school assignment. It it a simple rock paper scissor app in kotlin built for android. Using local storage to record history of the moves played.',
      technostack: [this.android],
      link: 'https://github.com/Sebaszjuh/MadLevel4Task2',
      icon: this.github,
      // image: '/assets/crawlinator.png',
      imageAlt: 'crawlinaTOR'
    },
    {
      title: 'Movie DB App',
      // tslint:disable-next-line:max-line-length
      description: 'This project built for android devices uses an external API from the movie database to find movies per release year. More details can be seen when clicked on the poster.',
      technostack: [this.android],
      link: 'https://github.com/Sebaszjuh/MadLevel6Task2',
      icon: this.github,
      // image: '/assets/crawlinator.png',
      imageAlt: 'crawlinaTOR'
    },
    {
      title: 'Resume',
      // tslint:disable-next-line:max-line-length
      description: 'This small project was to show off my basic understanding in Java & JavaFX. It resembles my resume with UI in JavaFX it was a small fun project.',
      technostack: [this.java],
      link: '',
      icon: this.github,
      // image: '/assets/crawlinator.png',
      imageAlt: 'crawlinaTOR'
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth >= 772) {
      this.windowSize = true;
    } else {
      this.windowSize = false;
    }
  }

  constructor() {
  }

  ngOnInit() {
    if (window.innerWidth >= 772) {
      this.windowSize = true;
    } else {
      this.windowSize = false;
    }
  }

}
